.circle-arrow{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: #AA8B3B;
}
.icon-rotate {
    transition: transform 0.3s ease-in-out; /* 애니메이션 효과 */
}

.icon-rotate-hover {
    transform: rotate(-45deg); /* 45도 회전 */
}
