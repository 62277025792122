/* PC Version */
.property-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border : 1px solid #d9d9d9;
   width: 1000px;
    margin-top: 60px;
    margin-bottom: 60px;
    padding-top: 60px;
    padding-bottom: 60px;
}

.property-img-container {
    position: relative;
    width: 100%;
    max-width: 900px;
}

.slider {
    display: flex;
    align-items: center;
}

.slider img {
    width: 100%;
    height: auto;
}

.slider-button {
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
}

.slider-button:first-of-type {
    left: 10px;
}

.slider-button:last-of-type {
    right: 10px;
}

.property-details {
    text-align: left;
    width: 100%;
    max-width: 900px;
    padding-top: 20px;
}

/* Mobile Version */
.content-container-mobile {
    padding: 10px;
}

.property-img-container-mobile {
    position: relative;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}

.slider-mobile {
    display: flex;
    align-items: center;
}

.property-image-mobile {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.slider-button-mobile {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px;
}

.slider-button-mobile:first-of-type {
    left: 5px;
}

.slider-button-mobile:last-of-type {
    right: 5px;
}

.property-details-mobile {
    /* margin-top: 30px; */
    text-align: left;
    padding: 10px;
    word-wrap: break-word; /* 긴 단어가 줄바꿈되도록 설정 */
    overflow-wrap: break-word; /* 긴 텍스트가 잘리는 경우 줄바꿈 */
    white-space: normal; /* 텍스트를 여러 줄로 표시 */
    text-align: left;
    width: 100%;
    max-width: 300px;
    padding-top: 20px;
}

.info-table table {
    border-collapse: collapse;
  }
  
  .info-table th, .info-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .info-table th {
    width: 100px;
    background-color: rgba(245, 233, 199, 0.5);
    font-weight: bold;
  }
  .info-table td {
    word-wrap: break-word; /* Allows wrapping of long words */
    overflow-wrap: break-word; /* Breaks long words at the end of the line */
    white-space: normal; /* Ensures text can span multiple lines */
    width: 350px;
    max-width: 350px;
  }
  /* mobile */
  .m-info-table table {
    border-collapse: collapse;
  }
  
  .m-info-table th, .m-info-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .m-info-table th {
    width: 100px;
    background-color: rgba(245, 233, 199, 0.5);
    font-weight: bold;
  }
  .m-info-table td {
    word-wrap: break-word; /* Allows wrapping of long words */
    overflow-wrap: break-word; /* Breaks long words at the end of the line */
    white-space: normal; /* Ensures text can span multiple lines */
    width: 350px;
    max-width: 350px;
  }
  .note-link {
    color: blue;
    text-decoration: none;
  }
  
  .note-link:hover {
    text-decoration: underline;
  }
  .title-col{
    width: 900px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  .price-td{
    color: red;
    font-size: 24px;
    font-weight: 600;
  }