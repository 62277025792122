.contact-area {
    display: flex;
    flex-direction: row;
   align-items: flex-start;
   justify-content: flex-start;
    width: calc(100% - 200px - 200px);
    /* height: 700px; */
    margin-left: 200px;
    margin-right: 200px;
    background-color: #DAC5A7;
    padding-bottom: 100px;

}
.contact-area-mb {
    display: flex;
    flex-direction: column;
   align-items: center;
   justify-content: flex-start;
    width: 100%;
    padding-bottom: 30px;
    /* height: 700px; */

    background-color: #DAC5A7;

}
.main-contact-txts {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    width: 450px;
    margin-left: 200px;
}

.contact-txt-top {
    font-weight: 500;
    color: #4f3919;
    font-size: 45px;
}

.contact-txt-bottom {
    font-weight: 500;
    color: #4f3919;
    font-size: 28px;

}
.profile-img{
    margin-top: 100px;
    margin-left: 100px;
    width: 300px;
    height: 350px;
    /* background-color: #4f3919; */
}
.profile-img-mb{
    margin-top: 30px;
    width: 300px;
    height: 280px;
    /* background-color: #4f3919; */
}
.contact-txts{
    margin-top: 100px;
    margin-left: 100px;
    display: flex;
    flex-direction: column;
    gap: 32px;

}
.contact-txts-mb{
    margin-top: 30px;
    margin-left: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px; 
}
.contact-info{
    font-size: 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.contact-info-mb{
    font-size: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.info-title{
    color: rgb(105, 80, 17)
}
.info-content{
    font-size: 20px;
}
.info-content-mb{
    font-size: 23px;
}
.contact-title{
    font-size: 25px;
    font-weight: 500;
   color: rgb(105, 80, 17)
}
.my-info{
    font-size: 24px;
    color: #888888;
    font-weight: bold;
    width: calc(100% - 200px - 200px);
    margin-left: 200px;
    margin-right: 200px;
    margin-top: 200px;
    margin-bottom: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.my-info-mb{
    font-size: 24px;
    color: #888888;
    font-weight: bold;
    width: calc(100% - 30px - 30px);
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 60px;
    margin-bottom: 60px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.history-ul{
    padding-top: 60px;
    padding-bottom: 60px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.history-li{
    font-size: 32px;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 20px;
    width: calc(100% - 600px - 300px);
    margin-left: 600px;
    margin-right: 300px;
    margin-top: 50px;
    margin-bottom: 50px;
}
.year{
    font-weight: 600;
    font-size: 36px;
}
.history-ul-mb{
    margin: 0;
    padding: 0;
    padding-top: 60px;
    padding-bottom: 60px;
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.history-li-mb{
    font-size: 16px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 20px;
    width: calc(100% - 30px - 30px);
    margin-left: 30px;
    margin-right: 30px;
    /* width: calc(100% - 600px - 300px);
    margin-left: 600px;
    margin-right: 300px;
    margin-top: 20px;
    margin-bottom: 20px; */
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #d9d9d9;
}
.year-mb{
    font-weight: 600;
    font-size: 26px;
}