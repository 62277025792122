
@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');

body {
  margin: 0;
  font-family: 'Pretendard', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.MuiInputBase-input  {
  /* height: 36px !important;
  max-height: 36px !important; */
  width: 100% !important;
}
.MuiAutocomplete-inputRoot{
   flex-wrap: nowrap !important;
   height: 36px !important;
   max-height: 36px !important;
   width: 100% !important;
   /* padding-right: 30px !important; */
   overflow: hidden;
}
input[type='date']::before {
  content: attr(data-placeholder);
  width: 100%;
}

input[type='date']:focus::before,
input[type='date']:valid::before {
  display: none;
}
.fade-in {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeIn 1.5s ease-out forwards;
}

@keyframes fadeIn {
  to {
      opacity: 1;
      transform: translateY(0);
  }
}
