.list-properties-container{
    /* width: calc(100% - 60px - 60px); */
    /* margin-left: 60px;
    margin-right: 60px; */
    padding-top: 60px;
    min-height: calc(100vh - 200px - 60px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.btn-row{
    width: 1600px;
    display: flex;
    flex-direction: row;
}
.btn-row-mb{
    width: 300px;
}