.purchase-txt{
    width: calc(100% - 400px - 400px);
    margin-left: 400px;
    margin-right: 400px;
    margin-top: 200px;
    margin-bottom: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    font-size: 24px;
    color: #888888;
}
.purchase-txt-mb{
    
    width: calc(100% - 30px - 30px);
    margin-left: 30px;
    margin-right: 30px;
    padding-top: 80px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    font-size: 24px;
    color: #888888;
}