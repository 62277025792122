.home-sg-pg{
    display: flex;
    flex-direction: column;
    justify-content: center;   
}
.staging-txt{
    width: calc(100% - 500px - 500px);
    margin-left: 500px;
    margin-right: 500px;
    margin-top: 200px;
    margin-bottom: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    font-size: 24px;
    color: #888888;
}
.staging-txt-mb{
    width: calc(100% - 30px - 30px);
    margin-left: 30px;
    margin-right: 30px;
    padding-top: 80px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    font-size: 24px;
    color: #888888;
}